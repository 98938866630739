@import '../../../assets/sass/animations.scss';
@import '../../../assets/sass/theme.scss';

.room {
  color: white;
  background: $primary-color;
  background: radial-gradient(
    circle,
    $primary-color,
    darken($primary-color, 14%) calc(1rem + 64vmin)
  );
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: fadein 1s;
  -moz-animation: fadein 1s;
  animation: fadein 1s;

  > h3 {
    text-align: center;
    font-weight: 300;
    margin-bottom: 1em;
  }
}

.exit-appointment-button {
  z-index: 42000;
  border-radius: 3em;
  position: absolute;
  top: 1rem;
  padding: 0;
  right: 1rem;

  border-radius: 50%;
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;

  &:hover {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.zoom-button {
  z-index: 42000;
  border-radius: 3em;
  position: absolute;
  top: 1rem;
  padding: 0;
  right: 3rem;

  border-radius: 50%;
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
}

.local-participant {
  text-align: center;
  .participant {
    z-index: 4201;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 15vw;
    transition: all 0.2s ease-in-out;
    &:hover {
      right: calc(15vw * 0.25);
      bottom: calc(15vw * 0.125);
      transform: scale(1.5);
    }
  }
}

.local-participant-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc(15vw);
  height: calc(15vw * 0.5625);
}

.local-participant-loader-spinner {
  width: calc(0.8em + 5vmin);
  height: calc(0.8em + 5vmin);
}

.remote-participant {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  .participant {
    z-index: 4200;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.remote-participant.zoom {
  .participant {
    width: 150%;
    height: 150%;
    top: -25%;
    left: -25%;
  }
}
