@import '../../../assets/sass/animations';

.toolbar {
  width: 100%;
  margin: auto;
  position: fixed;
  bottom: 0;
  height: 6rem;
  z-index: 6000;
  cursor: pointer;

  div {
    display: none;
    -webkit-animation: fadein 1s;
    -moz-animation: fadein 1s;
    animation: fadein 1s;
    font-size: 4rem;
    padding: 1rem;
    padding-top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    line-height: 4rem;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
      -webkit-transition: background-color 500ms ease-in;
      -ms-transition: background-color 500ms ease-in;
      transition: background-color 500ms ease-in;
    }

    &:first-child {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
    }

    &:last-child {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
  }

  &:hover {
    div {
      display: inline-block;
    }
  }
}
