@import '../../../assets/sass/animations.scss';
@import '../../../assets/sass/theme.scss';

.lobby {
  -webkit-animation: fadein 1s;
  -moz-animation: fadein 1s;
  animation: fadein 1s;

  .appointment-code-input {
    input {
      font-weight: bold;
      font-size: calc(0.25em + 2vmax);
    }
  }

  .code-prompt {
    user-select: none;
  }

  .submit-button {
    width: 6em;
    background: linear-gradient(
      222deg,
      lighten($primary-color, 14%) 0%,
      $primary-color 100%
    );
  }
}
