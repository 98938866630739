@import '../../../assets/sass/animations';
@import '../../../assets/sass/theme';

.app-header {
  font-size: calc(1em + 2vmin);
  color: white;
  width: 100%;
  -webkit-animation: fadein 1s;
  -moz-animation: fadein 1s;
  animation: fadein 1s;
}

.header-container {
  background: $primary-color;
  background: linear-gradient(
    222deg,
    lighten($primary-color, 14%) 0%,
    $primary-color 100%
  );
}

.header-home-icon {
  background: none;
  transition: color 0.6s linear;
  &:hover {
    color: darken(white, 10%);
  }
}

.header-profile-picture {
  max-width: 2em;
  border: 2px solid white;
}
